.profile-background {
  height: 100vh;
  background-color: white;
}

.profile-container {
  width: 100%;
  display: inline-block;
}
@media only screen and (min-width: 801px) {
  .profile-container {
    width: 400px;
  }
}
@media only screen and (max-width: 800px) {
  .heightFixed {
    height: calc(100vh - 80px);
    overflow: hidden;
  }
}
.profile-list-container {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  background-color: white;
  width: calc(100% - 400px);
}

.profile-list {
  box-sizing: border-box;
  background-color: var(--primary-light-gray);
  color: var(--primary-dark-gray);
  padding: 50px 0px 30px;
  text-align: start;
  z-index: 4;
  min-height: calc(100vh - 75px);
}
@media only screen and (max-width: 800px) {
  .profile-list {
    background-color: white !important;
    padding: 50px 0px 30px;
  }
  .profile-list-debts {
    height: calc(100vh - 270px) !important;
  }
}

.profile-list-title {
  font-size: 30px;
  font-weight: 600;
  padding: 0px 35px 10px;
}

.profile-list-footer {
  padding: 10px 25px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 97px;
  width: calc(100% - 400px);
  z-index: 2;
  background-color: white;
}
@media only screen and (max-width: 800px) {
  .profile-list-footer {
    position: fixed;
    width: 100%;
  }
}

.profile-list-card-container {
  padding: 15px 35px 0px;
  height: calc(100vh - 270px);
  min-height: 345px;
  box-sizing: border-box;
  overflow-y: auto;
}
@media only screen and (max-width: 800px) {
  .profile-list-card-container {
    height: auto;
    padding: 25px 25px 0px;
  }
}

.profile-list-card-payButton {
  box-sizing: border-box;
  text-align: center;
  padding: 13px 40px;
  color: white;
  background-color: var(--primary-red);
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}
@media only screen and (max-width: 400px) {
  .profile-list-card-payButton {
    padding: 13px 32px;
  }
}

.profile-list-paymentMethods-container {
  padding: 25px 20px 0px;
  height: calc(100vh - 342px);
  min-height: 195px;
  box-sizing: border-box;
  overflow-y: auto;
}
@media only screen and (max-width: 800px) {
  .profile-list-paymentMethods-container {
    height: auto;
    padding: 25px 10px 0px;
  }
}

.profile-list-card {
  padding: 20px 40px;
  background-color: white;
  color: var(--primary-dark-gray);
  border-radius: 20px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 800px) {
  .profile-list-card {
    padding: 20px 25px;
  }
}

.profile-list-card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid var(--primary-light-gray);
}

.profile-list-card-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.profile-list-date {
  margin: 30px 35px 15px;
  font-size: 18px;
  font-weight: 600;
  color: #828282;
}

.profile-header {
  padding: 45px 35px;
  background-color: var(--primary-red);
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.profile-icon {
  color: white;
  height: 24px !important;
  width: auto !important;
  cursor: pointer;
}

.profile-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 3px;
}

.profile-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-bottom: 15px;
}

.profile-description {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.profile-link {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-red);
  text-decoration: underline;
  cursor: pointer;
}

.profile-whiteBodyDesktop {
  padding: 22px 0px 10px;
  background-color: white;
  color: var(--primary-dark-gray);
}

.profile-whiteBodyMobile {
  padding: 35px 20px 0px;
  background-color: white;
  color: var(--primary-dark-gray);
  text-align: center;
  box-sizing: border-box;
}

.profile-optionsIcons {
  padding: 0px 20px 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-light-gray);
}

.profile-optionContainer {
  display: inline-block;
  vertical-align: top;
  padding: 0px 6px 30px;
  box-sizing: border-box;
  width: 50%;
}
@media only screen and (min-width: 450px) {
  .profile-optionContainer {
    width: 20%;
  }
}

.profile-optionsIcon {
  font-size: 20px;
  height: 60px;
  width: 60px;
  border: none;
  outline: none;
  border-radius: 36px;
  background-color: var(--primary-light-gray);
  color: var(--primary-red);
  margin-bottom: 12px;
}

.profile-options-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-dark-gray);
}

.profile-lotesAmount {
  font-size: 30px;
  font-weight: bold;
  margin-right: 12px;
}
@media only screen and (min-width: 801px) {
  .profile-lotesAmount {
    font-size: 60px;
  }
}

.profile-lotesButton {
  text-align: center;
  padding: 16px 60px;
  color: var(--primary-dark-gray);
  background-color: var(--primary-light-gray);
  font-size: 18px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 30px;
}

.profile-overlineTitle {
  display: inline-block;
  box-sizing: border-box;
  width: 237px;
  font-size: 17px;
  color: var(--primary-dark-gray);
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: start;
  padding: 0px 20px;
}

.profile-line {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 237px);
  height: 11px;
  border-bottom: 1px solid var(--primary-light-gray);
}

.profile-quote-card {
  padding: 24px 20px;
  color: white;
  background-color: var(--primary-dark-gray);
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  text-align: start;
  width: 100%;
  box-sizing: border-box;
}
@media only screen and (min-width: 801px) {
  .profile-quote-card {
    padding: 24px 40px;
  }
}

.profile-quote-price {
  font-size: 17px;
  letter-spacing: 1.4px;
}

.profile-status {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-red);
  background-color: var(--primary-light-gray);
  padding: 10px 20px;
  outline: none;
  border: none;
  border-radius: 20px;
}
@media only screen and (min-width: 801px) {
  .profile-status {
    padding: 11px 38px;
  }
}

.profile-status-selected {
  background-color: #888888 !important;
  color: var(--primary-dark-gray) !important;
}

.profile-quote-card-title {
  font-size: 18px;
  font-weight: 600;
}

/*Profile settings*/
.profile-inputs-loading {
  height: calc(100vh - 255px);
  padding: 10px 37px 0px;
  margin-bottom: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-inputs-container {
  height: calc(100vh - 255px);
  max-width: 800px;
  overflow-y: auto;
  padding: 10px 37px 0px;
  margin-bottom: 25px;
  box-sizing: border-box;
}
@media only screen and (min-width: 800px) {
  .profile-inputs-container {
    margin-left: calc(50% - 390px);
  }
}

.profile-button {
  text-align: center;
  padding: 16px 55px;
  color: var(--primary-red);
  background-color: var(--primary-light-gray);
  font-size: 18px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 30px;
}

.profile-input-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--primary-dark-gray);
}

.profile-input {
  border: none;
  outline: none;
  padding: 5px 0px;
  font-size: 18px;
  border-bottom: 1px solid var(--primary-light-gray);
  color: var(--primary-dark-gray);
  width: 100%;
}

.profile-modal-input {
  border: none;
  outline: none;
  padding: 5px 0px;
  font-size: 18px;
  border-bottom: 1px solid var(--primary-dark-gray);
  color: var(--primary-dark-gray);
  background-color: var(--primary-light-gray);
  width: 100%;
  margin-bottom: 20px;
}

.profile-listScreen {
  position: fixed;
  top: 75px;
  left: 0px;
  background-color: white;
  height: calc(100vh - 75px);
  width: 100%;
  z-index: 5;
  overflow-y: auto;
}

.profile-menuOption {
  padding: 18px 35px 18px 60px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.profile-menuOption-border {
  border-bottom: 2px solid var(--primary-light-gray);
}

.profile-menuOption-selected {
  background-color: var(--primary-light-gray);
}

.profile-dev-card {
  background-color: white;
  display: block;
  color: #333;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card {
    display: flex;
    margin-bottom: 30px;
  }
}

.profile-dev-card-map {
  width: 100%;
  height: 110px;
  background-color: var(--primary-light-gray);
  border-radius: 20px 20px 0px 0px;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-map {
    width: calc(-50px + 40%);
    min-width: 80px;
    max-width: 350px;
    height: inherit;
    border-radius: 20px 0px 0px 20px;
  }
}

.profile-dev-card-map a.mapboxgl-ctrl-logo,
.profile-dev-card-map .mapboxgl-ctrl-bottom-right {
  opacity: 0.05 !important;
}

.profile-dev-card-body {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 22px 10px 12px 20px;
  overflow: hidden;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-body {
    width: calc(50px + 60%);
    padding: 25px 20px 15px 40px;
  }
}

.profile-dev-card-title {
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  font-weight: 700;
  width: 100px;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-title {
    display: block;
    font-size: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

.profile-dev-card-details {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 100px);
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-details {
    width: 100%;
  }
}

.profile-dev-card-detail {
  padding-right: 10px;
  width: 80px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 401px) {
  .profile-dev-card-detail {
    width: 90px;
  }
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-detail {
    padding-right: 20px;
    width: calc(75px + 20%);
  }
}

.profile-dev-card-subtitle {
  font-size: 13px;
  color: var(--primary-dark-gray);
  margin-bottom: 3px;
}
@media only screen and (min-width: 401px) {
  .profile-dev-card-subtitle {
    font-size: 14px;
  }
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-subtitle {
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
}

.profile-dev-card-data {
  font-size: 13px;
  font-weight: 600;
}
@media only screen and (min-width: 401px) {
  .profile-dev-card-data {
    font-size: 14px;
  }
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-data {
    font-size: 22px;
  }
}

#bancardIframe {
  padding: 20px 30px;
}

.profile-pagosRecurrentes {
  background-color: var(--primary-light-gray);
  font-size: 14px;
  padding: 40px 20px;
  margin-bottom: 20px;
}

.profile-button-mini-white {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: var(--primary-light-gray);
  color: var(--primary-dark-gray);
}

.profile-button-mini-white:disabled {
  opacity: 0.5;
  cursor: default;
}

.profile-button-mini-red {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: var(--primary-red);
  color: white;
}
