@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-red: #d22c3f;
  --primary-light-gray: #ededed;
  --primary-dark-gray: #585856;
  --primary-green: #61e73f;
  --primary-yellow: #f2de24;
  --detailHeaders-height: 75px;
}

body,
input,
option,
button,
select {
  font-family: "Source Sans Pro", sans-serif;
}

button {
  cursor: pointer;
}

body {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #585856 #ededed;
  scrollbar-color: var(--primary-dark-gray) var(--primary-light-gray);
  /* scroll thumb and track */
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ededed;
  background: var(--primary-light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #585856;
  background: var(--primary-dark-gray);
  border: 1px #ededed solid;
  border: 1px var(--primary-light-gray) solid;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.p-5 {
  padding: 25px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-9 {
  margin-top: 45px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

.mb-5 {
  margin-bottom: 25px !important;
}

.mb-6 {
  margin-bottom: 30px !important;
}

.mb-7 {
  margin-bottom: 35px !important;
}

.mb-8 {
  margin-bottom: 40px !important;
}

.mx-5 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.textCentered {
  text-align: center !important;
}

.noPadding {
  padding: 0px !important;
}

.noBorder {
  border: none !important;
}

.sub-title {
  font-size: 20px;
  font-weight: bold;
  padding-top: 26px;
  padding-bottom: 30px;
  background-color: white;
}

.inmo-checkbox {
  display: inline-block;
  height: 24px;
  width: 24px;
  border: solid 1px #585856;
  border: solid 1px var(--primary-dark-gray);
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .mobile-noDisplay {
    display: none !important;
  }
}

@media only screen and (min-width: 801px) {
  .desktop-noDisplay {
    display: none !important;
  }
}

@media only screen and (max-width: 970px) {
  .littleDesktop-noDisplay {
    display: none !important;
  }
}

@media only screen and (max-width: 490px) {
  .brujula-noDisplay {
    display: none !important;
  }
}

@media only screen and (min-width: 801px) {
  .brujula-noDisplay {
    display: none;
  }
}

@media only screen and (min-width: 890px) {
  .brujula-noDisplay {
    display: inherit;
  }
}

@media only screen and (min-width: 971px) {
  .bigDesktop-noDisplay {
    display: none !important;
  }
}

.tooltip {
  position: absolute;
  margin: 8px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 14px;
  z-index: 3;
  pointer-events: none;
}

.shadow1 {
  /*Shadows for every platform*/
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2);
}

.shadow2 {
  /*Shadows for every platform*/
  box-shadow: 0px 4px 15px 2px rgba(0, 4, 2, 0.1);
}

.shadow3 {
  /*Shadows for every platform*/
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.2);
}

.modal {
  position: absolute;
  bottom: 50%;
  left: calc(var(--filters-width) / 2 + 50%);
  transform: translate(-50%, 50%);
  border-radius: 20px;
  width: calc(100% - 440px);
  min-width: 250px;
  max-width: 500px;
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  color: black;
  z-index: 10;
}

@media only screen and (max-width: 800px) {
  .modal {
    width: calc(100% - 40px);
    bottom: 0;
    transform: translate(-50%, 0%);
    border-radius: 20px 20px 0px 0px;
  }
}

.bigModal {
  left: 50% !important;
  width: calc(100% - 40px) !important;
  max-width: 100% !important;
  min-height: calc(100vh - 100px);
  z-index: 11;
}

.bigModal iframe {
  height: calc(100vh - 210px);
}

.modal-button {
  text-align: center;
  padding: 16px 20px;
  color: white;
  background-color: #d22c3f;
  background-color: var(--primary-red);
  font-size: 18px;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  margin-top: 40px;
}

.googleMapsButton {
  margin-top: 20px;
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  color: #585856;
  color: var(--primary-dark-gray);
}

.modal-button2 {
  text-align: center;
  padding: 18px 20px;
  color: "#555555";
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  font-size: 22px;
  letter-spacing: 0.7px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  margin-top: 10px;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-loteState {
  font-size: 18px;
  font-weight: 600;
}

.modal-close {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 30px;
  text-align: center;
}

.modal-body {
  padding: 25px 30px;
  width: 100%;
  box-sizing: border-box;
}

.modal-data {
  width: 100%;
  font-size: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-data-dimensions {
  width: 180px;
}

@media only screen and (max-width: 410px) {
  .modal-data-dimensions {
    width: calc(100% - 130px);
  }
}

@media only screen and (max-width: 360px) {
  .modal-data-dimensions {
    width: calc(100% - 120px);
  }
}

@media only screen and (max-width: 340px) {
  .modal-data-dimensions {
    font-size: 14px !important;
  }
}

.weight500 {
  font-weight: 500;
}

.weight600 {
  font-weight: 600;
}

.redBackground {
  background-color: #d22c3f !important;
  background-color: var(--primary-red) !important;
}

.redColor {
  color: #d22c3f !important;
  color: var(--primary-red) !important;
}

.greenBackground {
  background-color: #61e73f !important;
  background-color: var(--primary-green) !important;
}

.greenBackground2 {
  background-color: #4bc42d !important;
}

.greenColor {
  color: #61e73f !important;
  color: var(--primary-green) !important;
}

.yellowBackground {
  background-color: #f2de24 !important;
  background-color: var(--primary-yellow) !important;
}

.yellowColor {
  color: #f2de24 !important;
  color: var(--primary-yellow) !important;
}

.whiteBackground {
  background-color: white !important;
}

.whiteColor {
  color: white !important;
}

.whatsappColor {
  background-color: #25d366 !important;
}

.darkgrayBackground {
  background-color: #585856 !important;
  background-color: var(--primary-dark-gray) !important;
}

.darkgrayColor {
  color: #585856 !important;
  color: var(--primary-dark-gray) !important;
}

.lightgrayColor {
  color: #ededed !important;
  color: var(--primary-light-gray) !important;
}

.blackColor {
  color: black !important;
}

/*Options modal*/
.optionsModal {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  border-radius: 20px 20px 0px 0px;
  left: calc(var(--filters-width) / 2 + 50%);
  /*left: 0px;*/
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  width: calc(100% - 420px);
  min-width: 200px;
  max-width: 900px;
  z-index: 11;
}

@media only screen and (max-width: 800px) {
  .optionsModal {
    width: calc(100% - 20px);
  }
}

.modal-header {
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 35px;
  display: inline-flex;
  justify-content: space-between;
  background-color: white;
}

.optionsModal-title {
  font-size: 20px;
  font-weight: 600;
}

.optionsModal-close {
  width: 30px;
}

.optionsModal-button {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
  padding: 15px 26px;
  color: white;
  background-color: #d22c3f;
  background-color: var(--primary-red);
  border-radius: 30px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
}

.optionsModal-body {
  padding: 0px 20px;
  height: 340px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

@media only screen and (min-width: 801px) {
  .optionsModal-body {
    overflow-y: hidden;
  }
}

@media only screen and (min-width: 1000px) {
  .optionsModal-body {
    height: 170px;
    overflow-y: hidden;
  }
}

.optionsModal-option {
  padding: 30px 15px;
  height: 170px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

@media only screen and (min-width: 600px) {
  .optionsModal-option {
    width: 50%;
  }
}

@media only screen and (min-width: 801px) {
  .optionsModal-option {
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .optionsModal-option {
    width: 50%;
  }
}

.optionsModal-text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 35px;
}

.optionsModal-range {
  width: calc(100% - 57px);
  margin: 0px;
  display: inline-block;
}

.optionsModal-rangeNumber {
  font-size: 20px;
  text-align: end;
  width: 57px;
  display: inline-block;
}

/*###*/

/*legendModal*/
.legendModal {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px 0px 0px 0px;
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  width: 225px;
  z-index: 8;
}

.legendModal .modal-header {
  padding: 15px 25px 12px;
  border-radius: 20px 0px 0px 0px;
}

@media only screen and (max-width: 800px) {
  .legendModal {
    width: calc(100% - 20px);
    max-width: 450px;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 20px 20px 0px 0px;
  }

  .legendModal .modal-header {
    border-radius: 20px 20px 0px 0px;
  }
}

.legendModal-title {
  font-size: 20px;
  font-weight: 600;
}

.legendModal-body {
  padding: 16px 24px;
  box-sizing: border-box;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .legendModal-body {
    display: flex;
  }
}

.legendModal-square {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}

@media only screen and (min-width: 801px) {
  .legendModal-item {
    padding: 8px 0px;
  }
}

.legendModal-text {
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
}

.map-optionsButton {
  letter-spacing: 0.4px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 1.1;
  padding: 13px 25px;
  position: absolute;
  top: calc(80px);
  right: 20px;
  z-index: 2;
  background-color: white;
  color: #585856;
  color: var(--primary-dark-gray);
  border-radius: 30px;
  box-sizing: border-box;
  outline: none;
  border: none;
  cursor: pointer;
}

@media only screen and (min-width: 801px) {
  .map-optionsButton {
    top: calc(20px);
  }
}

/*This property makes react IGNORE onClick event on mapboxgl-marker, which is the generated div on <Marker />*/
.mapboxgl-marker {
  /*cursor: pointer;*/
  pointer-events: none;
}

.map-inMapNumber {
  font-weight: 600;
  color: white;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  pointer-events: none;
}

.map-inMapBlock {
  font-size: 22px;
  font-weight: 600;
  color: white;
  transform: translate(-50%, -50%);
}

.map-compass {
  position: absolute;
  top: 75px;
  left: 20px;
}

.map-compass .mapboxgl-ctrl-group {
  border-radius: 25px;
}

.map-compass button.mapboxgl-ctrl-compass {
  height: 45px;
  width: 45px;
}

.map-compass .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-size: contain;
}

@media only screen and (min-width: 801px) {
  .map-compass {
    top: 15px;
  }
}

.map-legendButton {
  display: inline-flex;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  color: white;
  position: absolute;
  right: 20px;
  bottom: 36px;
  padding: 13px 25px;
  border-radius: 30px;
  background-color: #585856;
  background-color: var(--primary-dark-gray);
  z-index: 2;
  border: none;
  outline: none;
  cursor: pointer;
}

.map-whatsappButton {
  position: absolute;
  left: 20px;
  bottom: 36px;
  text-align: center;
  height: 51px;
  width: 51px;
  border-radius: 26px;
  background-color: #25d366;
  z-index: 2;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (min-width: 801px) {
  .map-whatsappButton {
    left: 420px;
  }
}

.map-cluster {
  transform: translate(-50%, -50%);
  padding: 10px 0px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  color: black;
  background-color: white;
  border-radius: 50%;
  border: 4px solid #d22c3f;
  border: 4px solid var(--primary-red);
  -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
}

.map-marker {
  transform: translate(-50%, -100%);
  cursor: pointer;
  pointer-events: all !important;
}

.map-marker-body {
  padding: 14px 0px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  color: white;
  background-color: #d22c3f;
  background-color: var(--primary-red);
  border-radius: 50%;
  -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
}

.map-marker-arrow {
  margin-top: 5px;
  width: 60px;
  text-align: center;
  -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
}

/*To make it visible*/
/*.detailHeaders {
  --detailHeaders-height: 50px;
}*/

.popUp {
  position: absolute;
  bottom: 50%;
  left: 50%;
  min-width: 300px;
  min-height: 150px;
  transform: translate(-50%, 50%);
  border-radius: 20px;
  background-color: #ededed;
  background-color: var(--primary-light-gray);
  color: black;
  z-index: 10;
  font-size: 18px;
}

.popUp-body {
  padding: 20px 30px 35px;
}

.popUp-button {
  text-align: center;
  padding: 16px 32px;
  color: white;
  background-color: #d22c3f;
  background-color: var(--primary-red);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
}

.lowProfileButton {
  background-color: white;
  color: #585856;
  color: var(--primary-dark-gray);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  outline: none;
}

:root {
  --header-height: 75px;
  --filters-width: 400px;
  --close-width: 24px;
}

@media only screen and (max-width: 800px) {
  :root {
    --filters-width: 0px;
  }
  .mobile-noDisplay {
    display: none;
  }
}

.home-background-image {
  /*background-image: url("../assets/images/background.jpg");
  background-size: cover;
  background-position: center;*/
  height: 100vh;
  z-index: 1;
}

.home-background-color {
  background-color: rgba(210, 44, 63, 1);
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  z-index: 2;
}

.home-container {
  transform: translate(0px, calc(50vh - 50%));
}
@media only screen and (min-width: 801px) {
  .home-container {
    transform: translate(0px, calc(50vh - 75px / 2 - 50% - 30px));
    transform: translate(0px, calc(50vh - var(--header-height) / 2 - 50% - 30px));
    padding: 0px 75px;
  }
}

.home-header {
  background-color: white;
  height: 75px;
  height: var(--header-height);
  padding: 0px 70px;
  border-bottom: 1px solid var(--primary-light-gray);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 800px) {
  .home-header {
    padding: 0px 20px;
  }
}

.home-header-logo {
  height: 100%;
}

.home-profileWhite {
  position: absolute;
  width: 32px;
  top: 11px;
  right: 25px;
  padding: 8px;
  cursor: pointer;
}

.home-column {
  width: 45%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
@media only screen and (min-width: 801px) {
  .home-column-left {
    margin-right: 5%;
  }

  .home-column-right {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 800px) {
  .home-column {
    width: 90%;
    margin: 0% 5%;
  }
}

.home-inmo-logo {
  width: 70%;
  max-width: 310px;
  margin-bottom: 35px;
}

.home-input {
  font-size: 15px;
  letter-spacing: 1px;
  padding: 15px 32px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 35px;
  width: calc(95% - 64px);
  max-width: 350px;
  margin-bottom: 20px;
}

.home-search-filters {
  font-size: 15px;
  letter-spacing: 1px;
  padding: 15px 32px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 35px;
  width: 100%;
  box-sizing: border-box;
  margin: 30px 0px 15px;
}

.home-input-description {
  color: white;
  font-size: 13px;
  font-weight: 500;
  width: 85%;
  margin: 0% 7.5%;
}
@media only screen and (max-width: 800px) {
  .home-input-description {
    margin-bottom: 60px;
  }
}

.home-sugerencias {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: solid white 1px;
  width: 50%;
  cursor: pointer;
}

.home-sugerencias-selected {
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: solid white 2px;
}

.home-loteamientos-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}

.home-loteamientos-containerHeight {
  height: calc(100vh - 230px);
  overflow-y: auto;
  padding: 0px 10px;
}
@media only screen and (max-width: 800px) {
  .home-loteamientos-containerHeight {
    height: calc(100vh - 535px);
    padding: 0px;
  }
  @media only screen and (max-height: 630px) {
    .home-loteamientos-containerHeight {
      height: calc(100vh - 440px);
      padding: 0px;
    }
  }
}
@media only screen and (min-width: 801px) {
  @media only screen and (max-height: 630px) {
    .home-loteamientos-containerHeight {
      height: calc(95vh - 200px);
    }
  }
}

.home-loteamientos-emptyState {
  padding: 0px 14px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.home-loteamiento {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white;
  padding: 12px 20px;
  width: calc(48.5% - 40px);
  margin-bottom: 12px;
  border-radius: 10px;
  line-height: 1;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .home-loteamiento {
    margin-bottom: 12px;
    width: calc(50% - 50px);
  }
}

.home-loteamiento-title {
  color: var(--primary-red);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.home-loteamiento-body {
  color: var(--primary-dark-gray);
  font-size: 16px;
  font-weight: 400;
}

.home-loteamiento-left {
  margin-right: 1.5%;
}
.home-loteamiento-right {
  margin-left: 1.5%;
}
@media only screen and (max-width: 800px) {
  .home-loteamiento-left {
    margin-right: 4px;
  }
  .home-loteamiento-right {
    margin-left: 4px;
  }
}

.home-politica {
  color: white;
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  display: block;
  text-align: center;
  bottom: 40px;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .home-politica {
    bottom: 28px;
  }
}
@media only screen and (max-height: 630px) {
  .home-politica {
    display: none;
  }
}

.home-filters {
  /* Mobile */
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 3;
  background-color: white;
  height: calc(100vh - 75px - 130px);
  height: calc(100vh - var(--header-height) - 130px);
  width: calc(100% - 34px);
  margin: 0px 17px;
  border-radius: 24px 24px 0px 0px;
  padding-top: 10px;
}
@media only screen and (min-width: 801px) {
  .home-filters {
    /*top: var(--header-height);*/
    height: calc(100vh - 75px);
    height: calc(100vh - var(--header-height));
    width: 400px;
    margin: 0px;
    border-radius: 0px;
    padding-top: 0px;
  }
}

.home-filters-button {
  text-align: center;
  padding: 14px 20px;
  box-sizing: border-box;
  color: white;
  background-color: var(--primary-red);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  margin-top: 20px;
}

.home-filters-result-selected .home-filters-button {
  background-color: white !important;
  color: var(--primary-dark-gray) !important;
  cursor: default !important;
}

.home-header-profile {
  display: flex;
  width: 63px;
  justify-content: flex-end;
  cursor: pointer;
}

.home-back {
  box-sizing: border-box;
  height: 75px;
  height: var(--header-height);
  padding-top: calc(75px / 2 - 10px);
  padding-top: calc(var(--header-height) / 2 - 10px);
  cursor: pointer;
  width: 63px; /*for symmetry with profile*/
}

.home-back-arrow {
  height: 16px;
  margin-right: 20px;
}

.home-back-text {
  line-height: 1.1;
  vertical-align: top;
}

.home-filters-header {
  width: calc(100% - 20px);
  margin: 0px 10px 15px;
  padding: 15px 30px 12px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--primary-light-gray);
  font-size: 18px;
  font-weight: bold;
}

.home-filters-header-title {
  display: inline-block;
  width: calc(100% - 24px);
  width: calc(100% - var(--close-width));
  vertical-align: top;
}

.home-filters-header-close {
  width: 24px;
  width: var(--close-width);
  display: inline-block;
  vertical-align: middle;
}

.home-filters-dropdown {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 18px 22px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border: none;
  width: 100%;
  box-sizing: border-box;
  background: url(/loteamientos/static/media/dropdown.cd62c770.svg);
  background-color: white;
  background-repeat: no-repeat;
  background-position: top 26px right 22px;
  outline: none;
}

.extraLength {
  width: calc(100% - 188px) !important;
}

.home-mapInput {
  margin: 0px;
  position: absolute;
  top: calc(75px + 10px + var(--detailHeaders-height));
  top: calc(var(--header-height) + 10px + var(--detailHeaders-height));
  left: 20px;
  width: calc(100% - 214px);
  z-index: 2;
  border-radius: 30px 5px 5px 30px;
  max-width: 750px !important;
}
@media only screen and (min-width: 801px) {
  .home-mapInput {
    border-radius: 30px;
    width: calc(400px - 104px);
    width: calc(var(--filters-width) - 104px);
  }
}

.home-filtersButton {
  min-width: 73px;
  height: 49px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
  padding: 15px 26px;
  position: absolute;
  top: calc(75px + 10px + var(--detailHeaders-height));
  top: calc(var(--header-height) + 10px + var(--detailHeaders-height));
  right: 20px;
  color: white;
  background-color: var(--primary-red);
  z-index: 2;
  border: none;
  outline: none;
  border-radius: 5px 30px 30px 5px;
  cursor: pointer;
}

.home-filters-body {
  padding: 0px 25px;
  box-sizing: border-box;
  height: calc(100% - 100px);
  overflow-y: auto;
}
@media only screen and (max-width: 800px) {
  .home-filters-body {
    margin-top: 15px;
    height: calc(100% - 153px);
  }
}

.home-filters-tags {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 35px;
}
/*.home-filters-tags::-webkit-scrollbar {
  display: none;
}*/

.home-filters-tag {
  display: inline-block;
  padding: 8px 20px;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: var(--primary-dark-gray);
  letter-spacing: 0.5px;
  margin-right: 10px;
}

.home-filters-closeTag {
  height: 20px;
  margin-left: 10px;
  vertical-align: bottom;
}

.home-filters-title {
  padding: 0px 22px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

/*React input range component*/
.input-range {
  width: calc(100% - 20px) !important;
  margin: 5px 10px 5px;
}
.input-range__label {
  display: none;
}
.input-range__track--background {
  background-color: var(--primary-light-gray) !important;
}
.input-range__track--active,
.input-range__slider {
  border: none !important;
  background-color: var(--primary-red) !important;
}

.home-filters-checkContainer {
  display: inline-block;
  white-space: nowrap;
}

.home-filters-checkLabel {
  margin-left: 10px;
  margin-right: 25px;
  vertical-align: top;
  line-height: 1.7;
  white-space: nowrap;
}

.home-filters-redButton {
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 25px;
  margin-top: 20px;
  text-align: center;
  width: calc(50% - 30px);
  padding: 14px 23px;
  color: white;
  background-color: var(--primary-red);
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}

.home-filters-whiteButton {
  box-sizing: border-box;
  margin-left: 25px;
  margin-right: 5px;
  margin-top: 20px;
  text-align: center;
  width: calc(50% - 30px);
  padding: 14px 23px;
  color: "#555555";
  background-color: var(--primary-light-gray);
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}

.home-filters-whiteButton-fullwidth {
  box-sizing: border-box;
  margin: 20px 25px 0px;
  text-align: center;
  width: calc(100% - 50px);
  padding: 14px 23px;
  color: "#555555";
  background-color: var(--primary-light-gray);
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}

.home-filters-result {
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  width: calc(100% - 15px);
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 18px;
  cursor: pointer;
}

.home-filters-result-selected {
  color: white !important;
  background-color: var(--primary-dark-gray);
  cursor: inherit;
}

.home-filters-resultTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}

.home-filters-resultSubtitle {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.4;
}

.home-filters-resultBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 375px) {
  .home-filters-whiteButton,
  .home-filters-redButton {
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 50px);
  }
  .home-filters-redButton {
    margin-top: 12px;
  }
  .home-filters-body {
    height: calc(100% - 215px);
  }
}

.home-detailHeader {
  position: absolute;
  top: 75px;
  top: var(--header-height);
  left: 400px;
  left: var(--filters-width);
  height: var(--detailHeaders-height);
  width: calc(100% - 400px);
  width: calc(100% - var(--filters-width));
  background-color: white;
  color: var(--primary-dark-gray);
  text-align: center;
  font-size: 22px;
}

.home-detailHeader-title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  height: calc(var(--detailHeaders-height) / 3);
  background-color: var(--primary-dark-gray);
  color: white;
}

.home-detailHeader-body {
  font-size: 16px;
  width: 100%;
  height: calc(var(--detailHeaders-height) / 3);
  display: flex;
  justify-content: space-between;
  color: var(--primary-dark-gray);
  background-color: white;
  box-sizing: border-box;
  padding: 0px 10px;
}

/*.home-goBackToList {
  letter-spacing: 0.4px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 1.1;
  padding: 13px 25px;
  position: absolute;
  top: calc(150px + var(--detailHeaders-height));
  left: 20px;
  z-index: 2;
  background-color: var(--primary-red);
  color: white;
  border-radius: 30px;
  box-sizing: border-box;
  outline: none;
  border: none;
}
@media only screen and (min-width: 801px) {
  .home-goBackToList {
    top: calc(90px + var(--detailHeaders-height));
    left: 420px;
  }
}*/

.white-body {
  padding: 16px 16px 22px;
  background-color: white;
  border-radius: 0px 0px 24px 24px;
  text-align: center;
  max-width: 600px;
  box-sizing: border-box;
}
@media only screen and (min-width: 601px) {
  .white-body {
    padding: 18px 18px 25px;
    margin-left: calc(50% - 300px);
  }
}

.white-body-fixed {
  min-height: calc(100vh - 200px);
}

.login-title {
  color: var(--primary-dark-gray);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}

.login-description {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-dark-gray);
}

.login-social-button {
  color: var(--primary-dark-gray);
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  width: calc(50% - 10px);
  max-width: 250px;
  border-radius: 10px;
  margin: 0px 5px;
  border: none;
  outline: none;
}

.login-label {
  font-size: 18px;
  color: var(--primary-dark-gray);
  margin: 0px 10px 14px;
  text-align: start;
}

.login-input {
  border: none;
  outline: none;
  padding: 7px 0px 11px;
  font-size: 16px;
  border-bottom: 2px solid var(--primary-light-gray);
  width: 100%;
}

.login-input-halfWidth {
  border: none;
  outline: none;
  padding: 7px 0px 11px;
  font-size: 16px;
  border-bottom: 2px solid var(--primary-light-gray);
  width: calc(50% - 12px);
}

.login-input-resetPassword {
  width: calc(100% - 50px) !important;
}

.login-select {
  padding: 16px 24px;
  width: calc(100% - 20px);
  background-color: white;
  border-radius: 10px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  border: none;
  outline: none;
  margin: 0px 10px 33px;
}

.passwordShowerTrigger {
  height: 48px;
  width: 50px;
  background-color: white;
  border: none !important;
  outline: none !important;
  font-size: 22px;
  color: var(--primary-dark-gray);
}

.login-getPassword-link {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-red);
  text-align: end;
  cursor: pointer;
}

.login-link {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-dark-gray);
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.login-button-red {
  text-align: center;
  padding: 16px 50px;
  color: white;
  background-color: var(--primary-red);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
}
.login-button-red:disabled,
.login-button-red[disabled] {
  opacity: 0.5;
}

.button-smallPadding {
  padding: 16px 30px !important;
}

.login-checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login-checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  width: 19px;
  border-radius: 5px;
  border: 2px solid var(--primary-red);
  background-color: white;
  margin-right: 15px;
  cursor: pointer;
}

.checkbox-marked {
  background-color: var(--primary-red) !important;
}

.login-back {
  text-align: start;
  cursor: pointer;
  height: 32px;
}

.spinner {
  display: flex;
  justify-content: space-between;
  margin: 3px auto;
  width: 68px;
  text-align: center;
}

.spinner-red > div {
  background-color: var(--primary-red) !important;
}

.spinner-center {
  position: absolute !important;
  top: calc(50% - 70px);
  left: 50%;
  transform: translate(-50%, 0%);
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: white;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.profile-background {
  height: 100vh;
  background-color: white;
}

.profile-container {
  width: 100%;
  display: inline-block;
}
@media only screen and (min-width: 801px) {
  .profile-container {
    width: 400px;
  }
}
@media only screen and (max-width: 800px) {
  .heightFixed {
    height: calc(100vh - 80px);
    overflow: hidden;
  }
}
.profile-list-container {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  background-color: white;
  width: calc(100% - 400px);
}

.profile-list {
  box-sizing: border-box;
  background-color: var(--primary-light-gray);
  color: var(--primary-dark-gray);
  padding: 50px 0px 30px;
  text-align: start;
  z-index: 4;
  min-height: calc(100vh - 75px);
}
@media only screen and (max-width: 800px) {
  .profile-list {
    background-color: white !important;
    padding: 50px 0px 30px;
  }
  .profile-list-debts {
    height: calc(100vh - 270px) !important;
  }
}

.profile-list-title {
  font-size: 30px;
  font-weight: 600;
  padding: 0px 35px 10px;
}

.profile-list-footer {
  padding: 10px 25px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 97px;
  width: calc(100% - 400px);
  z-index: 2;
  background-color: white;
}
@media only screen and (max-width: 800px) {
  .profile-list-footer {
    position: fixed;
    width: 100%;
  }
}

.profile-list-card-container {
  padding: 15px 35px 0px;
  height: calc(100vh - 270px);
  min-height: 345px;
  box-sizing: border-box;
  overflow-y: auto;
}
@media only screen and (max-width: 800px) {
  .profile-list-card-container {
    height: auto;
    padding: 25px 25px 0px;
  }
}

.profile-list-card-payButton {
  box-sizing: border-box;
  text-align: center;
  padding: 13px 40px;
  color: white;
  background-color: var(--primary-red);
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}
@media only screen and (max-width: 400px) {
  .profile-list-card-payButton {
    padding: 13px 32px;
  }
}

.profile-list-paymentMethods-container {
  padding: 25px 20px 0px;
  height: calc(100vh - 342px);
  min-height: 195px;
  box-sizing: border-box;
  overflow-y: auto;
}
@media only screen and (max-width: 800px) {
  .profile-list-paymentMethods-container {
    height: auto;
    padding: 25px 10px 0px;
  }
}

.profile-list-card {
  padding: 20px 40px;
  background-color: white;
  color: var(--primary-dark-gray);
  border-radius: 20px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 800px) {
  .profile-list-card {
    padding: 20px 25px;
  }
}

.profile-list-card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid var(--primary-light-gray);
}

.profile-list-card-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.profile-list-date {
  margin: 30px 35px 15px;
  font-size: 18px;
  font-weight: 600;
  color: #828282;
}

.profile-header {
  padding: 45px 35px;
  background-color: var(--primary-red);
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.profile-icon {
  color: white;
  height: 24px !important;
  width: auto !important;
  cursor: pointer;
}

.profile-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 3px;
}

.profile-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-bottom: 15px;
}

.profile-description {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.profile-link {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-red);
  text-decoration: underline;
  cursor: pointer;
}

.profile-whiteBodyDesktop {
  padding: 22px 0px 10px;
  background-color: white;
  color: var(--primary-dark-gray);
}

.profile-whiteBodyMobile {
  padding: 35px 20px 0px;
  background-color: white;
  color: var(--primary-dark-gray);
  text-align: center;
  box-sizing: border-box;
}

.profile-optionsIcons {
  padding: 0px 20px 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-light-gray);
}

.profile-optionContainer {
  display: inline-block;
  vertical-align: top;
  padding: 0px 6px 30px;
  box-sizing: border-box;
  width: 50%;
}
@media only screen and (min-width: 450px) {
  .profile-optionContainer {
    width: 20%;
  }
}

.profile-optionsIcon {
  font-size: 20px;
  height: 60px;
  width: 60px;
  border: none;
  outline: none;
  border-radius: 36px;
  background-color: var(--primary-light-gray);
  color: var(--primary-red);
  margin-bottom: 12px;
}

.profile-options-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-dark-gray);
}

.profile-lotesAmount {
  font-size: 30px;
  font-weight: bold;
  margin-right: 12px;
}
@media only screen and (min-width: 801px) {
  .profile-lotesAmount {
    font-size: 60px;
  }
}

.profile-lotesButton {
  text-align: center;
  padding: 16px 60px;
  color: var(--primary-dark-gray);
  background-color: var(--primary-light-gray);
  font-size: 18px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 30px;
}

.profile-overlineTitle {
  display: inline-block;
  box-sizing: border-box;
  width: 237px;
  font-size: 17px;
  color: var(--primary-dark-gray);
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: start;
  padding: 0px 20px;
}

.profile-line {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 237px);
  height: 11px;
  border-bottom: 1px solid var(--primary-light-gray);
}

.profile-quote-card {
  padding: 24px 20px;
  color: white;
  background-color: var(--primary-dark-gray);
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  text-align: start;
  width: 100%;
  box-sizing: border-box;
}
@media only screen and (min-width: 801px) {
  .profile-quote-card {
    padding: 24px 40px;
  }
}

.profile-quote-price {
  font-size: 17px;
  letter-spacing: 1.4px;
}

.profile-status {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-red);
  background-color: var(--primary-light-gray);
  padding: 10px 20px;
  outline: none;
  border: none;
  border-radius: 20px;
}
@media only screen and (min-width: 801px) {
  .profile-status {
    padding: 11px 38px;
  }
}

.profile-status-selected {
  background-color: #888888 !important;
  color: var(--primary-dark-gray) !important;
}

.profile-quote-card-title {
  font-size: 18px;
  font-weight: 600;
}

/*Profile settings*/
.profile-inputs-loading {
  height: calc(100vh - 255px);
  padding: 10px 37px 0px;
  margin-bottom: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-inputs-container {
  height: calc(100vh - 255px);
  max-width: 800px;
  overflow-y: auto;
  padding: 10px 37px 0px;
  margin-bottom: 25px;
  box-sizing: border-box;
}
@media only screen and (min-width: 800px) {
  .profile-inputs-container {
    margin-left: calc(50% - 390px);
  }
}

.profile-button {
  text-align: center;
  padding: 16px 55px;
  color: var(--primary-red);
  background-color: var(--primary-light-gray);
  font-size: 18px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 30px;
}

.profile-input-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--primary-dark-gray);
}

.profile-input {
  border: none;
  outline: none;
  padding: 5px 0px;
  font-size: 18px;
  border-bottom: 1px solid var(--primary-light-gray);
  color: var(--primary-dark-gray);
  width: 100%;
}

.profile-modal-input {
  border: none;
  outline: none;
  padding: 5px 0px;
  font-size: 18px;
  border-bottom: 1px solid var(--primary-dark-gray);
  color: var(--primary-dark-gray);
  background-color: var(--primary-light-gray);
  width: 100%;
  margin-bottom: 20px;
}

.profile-listScreen {
  position: fixed;
  top: 75px;
  left: 0px;
  background-color: white;
  height: calc(100vh - 75px);
  width: 100%;
  z-index: 5;
  overflow-y: auto;
}

.profile-menuOption {
  padding: 18px 35px 18px 60px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.profile-menuOption-border {
  border-bottom: 2px solid var(--primary-light-gray);
}

.profile-menuOption-selected {
  background-color: var(--primary-light-gray);
}

.profile-dev-card {
  background-color: white;
  display: block;
  color: #333;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card {
    display: flex;
    margin-bottom: 30px;
  }
}

.profile-dev-card-map {
  width: 100%;
  height: 110px;
  background-color: var(--primary-light-gray);
  border-radius: 20px 20px 0px 0px;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-map {
    width: calc(-50px + 40%);
    min-width: 80px;
    max-width: 350px;
    height: inherit;
    border-radius: 20px 0px 0px 20px;
  }
}

.profile-dev-card-map a.mapboxgl-ctrl-logo,
.profile-dev-card-map .mapboxgl-ctrl-bottom-right {
  opacity: 0.05 !important;
}

.profile-dev-card-body {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 22px 10px 12px 20px;
  overflow: hidden;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-body {
    width: calc(50px + 60%);
    padding: 25px 20px 15px 40px;
  }
}

.profile-dev-card-title {
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  font-weight: 700;
  width: 100px;
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-title {
    display: block;
    font-size: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

.profile-dev-card-details {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 100px);
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-details {
    width: 100%;
  }
}

.profile-dev-card-detail {
  padding-right: 10px;
  width: 80px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 401px) {
  .profile-dev-card-detail {
    width: 90px;
  }
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-detail {
    padding-right: 20px;
    width: calc(75px + 20%);
  }
}

.profile-dev-card-subtitle {
  font-size: 13px;
  color: var(--primary-dark-gray);
  margin-bottom: 3px;
}
@media only screen and (min-width: 401px) {
  .profile-dev-card-subtitle {
    font-size: 14px;
  }
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-subtitle {
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
}

.profile-dev-card-data {
  font-size: 13px;
  font-weight: 600;
}
@media only screen and (min-width: 401px) {
  .profile-dev-card-data {
    font-size: 14px;
  }
}
@media only screen and (min-width: 801px) {
  .profile-dev-card-data {
    font-size: 22px;
  }
}

#bancardIframe {
  padding: 20px 30px;
}

.profile-pagosRecurrentes {
  background-color: var(--primary-light-gray);
  font-size: 14px;
  padding: 40px 20px;
  margin-bottom: 20px;
}

.profile-button-mini-white {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: var(--primary-light-gray);
  color: var(--primary-dark-gray);
}

.profile-button-mini-white:disabled {
  opacity: 0.5;
  cursor: default;
}

.profile-button-mini-red {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: var(--primary-red);
  color: white;
}

.confirmAddCard {
  height: 100vh;
}

.confirmAddCard-container {
  text-align: center;
  padding: 50px 40px 70px;
  font-size: 14px;
}

.confirmAddCard-status {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}

.confirmAddCard-button {
  text-align: center;
  padding: 16px 50px;
  color: white;
  background-color: var(--primary-red);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
}

