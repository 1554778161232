@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");

:root {
  --primary-red: #d22c3f;
  --primary-light-gray: #ededed;
  --primary-dark-gray: #585856;
  --primary-green: #61e73f;
  --primary-yellow: #f2de24;
  --detailHeaders-height: 75px;
}

body,
input,
option,
button,
select {
  font-family: "Source Sans Pro", sans-serif;
}

button {
  cursor: pointer;
}

body {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: var(--primary-dark-gray) var(--primary-light-gray);
  /* scroll thumb and track */
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-dark-gray);
  border: 1px var(--primary-light-gray) solid;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.p-5 {
  padding: 25px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-9 {
  margin-top: 45px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

.mb-5 {
  margin-bottom: 25px !important;
}

.mb-6 {
  margin-bottom: 30px !important;
}

.mb-7 {
  margin-bottom: 35px !important;
}

.mb-8 {
  margin-bottom: 40px !important;
}

.mx-5 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.textCentered {
  text-align: center !important;
}

.noPadding {
  padding: 0px !important;
}

.noBorder {
  border: none !important;
}

.sub-title {
  font-size: 20px;
  font-weight: bold;
  padding-top: 26px;
  padding-bottom: 30px;
  background-color: white;
}

.inmo-checkbox {
  display: inline-block;
  height: 24px;
  width: 24px;
  border: solid 1px var(--primary-dark-gray);
  background-color: var(--primary-light-gray);
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .mobile-noDisplay {
    display: none !important;
  }
}

@media only screen and (min-width: 801px) {
  .desktop-noDisplay {
    display: none !important;
  }
}

@media only screen and (max-width: 970px) {
  .littleDesktop-noDisplay {
    display: none !important;
  }
}

@media only screen and (max-width: 490px) {
  .brujula-noDisplay {
    display: none !important;
  }
}

@media only screen and (min-width: 801px) {
  .brujula-noDisplay {
    display: none;
  }
}

@media only screen and (min-width: 890px) {
  .brujula-noDisplay {
    display: inherit;
  }
}

@media only screen and (min-width: 971px) {
  .bigDesktop-noDisplay {
    display: none !important;
  }
}

.tooltip {
  position: absolute;
  margin: 8px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 14px;
  z-index: 3;
  pointer-events: none;
}

.shadow1 {
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2);
}

.shadow2 {
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 4px 15px 2px rgba(0, 4, 2, 0.1);
  -moz-box-shadow: 0px 4px 15px 2px rgba(0, 4, 2, 0.1);
  box-shadow: 0px 4px 15px 2px rgba(0, 4, 2, 0.1);
}

.shadow3 {
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.2);
}

.modal {
  position: absolute;
  bottom: 50%;
  left: calc(var(--filters-width) / 2 + 50%);
  transform: translate(-50%, 50%);
  border-radius: 20px;
  width: calc(100% - 440px);
  min-width: 250px;
  max-width: 500px;
  background-color: var(--primary-light-gray);
  color: black;
  z-index: 10;
}

@media only screen and (max-width: 800px) {
  .modal {
    width: calc(100% - 40px);
    bottom: 0;
    transform: translate(-50%, 0%);
    border-radius: 20px 20px 0px 0px;
  }
}

.bigModal {
  left: 50% !important;
  width: calc(100% - 40px) !important;
  max-width: 100% !important;
  min-height: calc(100vh - 100px);
  z-index: 11;
}

.bigModal iframe {
  height: calc(100vh - 210px);
}

.modal-button {
  text-align: center;
  padding: 16px 20px;
  color: white;
  background-color: var(--primary-red);
  font-size: 18px;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  margin-top: 40px;
}

.googleMapsButton {
  margin-top: 20px;
  background-color: var(--primary-light-gray);
  color: var(--primary-dark-gray);
}

.modal-button2 {
  text-align: center;
  padding: 18px 20px;
  color: "#555555";
  background-color: var(--primary-light-gray);
  font-size: 22px;
  letter-spacing: 0.7px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  margin-top: 10px;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-loteState {
  font-size: 18px;
  font-weight: 600;
}

.modal-close {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 30px;
  text-align: center;
}

.modal-body {
  padding: 25px 30px;
  width: 100%;
  box-sizing: border-box;
}

.modal-data {
  width: 100%;
  font-size: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-data-dimensions {
  width: 180px;
}

@media only screen and (max-width: 410px) {
  .modal-data-dimensions {
    width: calc(100% - 130px);
  }
}

@media only screen and (max-width: 360px) {
  .modal-data-dimensions {
    width: calc(100% - 120px);
  }
}

@media only screen and (max-width: 340px) {
  .modal-data-dimensions {
    font-size: 14px !important;
  }
}

.weight500 {
  font-weight: 500;
}

.weight600 {
  font-weight: 600;
}

.redBackground {
  background-color: var(--primary-red) !important;
}

.redColor {
  color: var(--primary-red) !important;
}

.greenBackground {
  background-color: var(--primary-green) !important;
}

.greenBackground2 {
  background-color: #4bc42d !important;
}

.greenColor {
  color: var(--primary-green) !important;
}

.yellowBackground {
  background-color: var(--primary-yellow) !important;
}

.yellowColor {
  color: var(--primary-yellow) !important;
}

.whiteBackground {
  background-color: white !important;
}

.whiteColor {
  color: white !important;
}

.whatsappColor {
  background-color: #25d366 !important;
}

.darkgrayBackground {
  background-color: var(--primary-dark-gray) !important;
}

.darkgrayColor {
  color: var(--primary-dark-gray) !important;
}

.lightgrayColor {
  color: var(--primary-light-gray) !important;
}

.blackColor {
  color: black !important;
}

/*Options modal*/
.optionsModal {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  border-radius: 20px 20px 0px 0px;
  left: calc(var(--filters-width) / 2 + 50%);
  /*left: 0px;*/
  background-color: var(--primary-light-gray);
  width: calc(100% - 420px);
  min-width: 200px;
  max-width: 900px;
  z-index: 11;
}

@media only screen and (max-width: 800px) {
  .optionsModal {
    width: calc(100% - 20px);
  }
}

.modal-header {
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 35px;
  display: inline-flex;
  justify-content: space-between;
  background-color: white;
}

.optionsModal-title {
  font-size: 20px;
  font-weight: 600;
}

.optionsModal-close {
  width: 30px;
}

.optionsModal-button {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
  padding: 15px 26px;
  color: white;
  background-color: var(--primary-red);
  border-radius: 30px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
}

.optionsModal-body {
  padding: 0px 20px;
  height: 340px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

@media only screen and (min-width: 801px) {
  .optionsModal-body {
    overflow-y: hidden;
  }
}

@media only screen and (min-width: 1000px) {
  .optionsModal-body {
    height: 170px;
    overflow-y: hidden;
  }
}

.optionsModal-option {
  padding: 30px 15px;
  height: 170px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

@media only screen and (min-width: 600px) {
  .optionsModal-option {
    width: 50%;
  }
}

@media only screen and (min-width: 801px) {
  .optionsModal-option {
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .optionsModal-option {
    width: 50%;
  }
}

.optionsModal-text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 35px;
}

.optionsModal-range {
  width: calc(100% - 57px);
  margin: 0px;
  display: inline-block;
}

.optionsModal-rangeNumber {
  font-size: 20px;
  text-align: end;
  width: 57px;
  display: inline-block;
}

/*###*/

/*legendModal*/
.legendModal {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px 0px 0px 0px;
  background-color: var(--primary-light-gray);
  width: 225px;
  z-index: 8;
}

.legendModal .modal-header {
  padding: 15px 25px 12px;
  border-radius: 20px 0px 0px 0px;
}

@media only screen and (max-width: 800px) {
  .legendModal {
    width: calc(100% - 20px);
    max-width: 450px;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 20px 20px 0px 0px;
  }

  .legendModal .modal-header {
    border-radius: 20px 20px 0px 0px;
  }
}

.legendModal-title {
  font-size: 20px;
  font-weight: 600;
}

.legendModal-body {
  padding: 16px 24px;
  box-sizing: border-box;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .legendModal-body {
    display: flex;
  }
}

.legendModal-square {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}

@media only screen and (min-width: 801px) {
  .legendModal-item {
    padding: 8px 0px;
  }
}

.legendModal-text {
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
}

.map-optionsButton {
  letter-spacing: 0.4px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 1.1;
  padding: 13px 25px;
  position: absolute;
  top: calc(80px);
  right: 20px;
  z-index: 2;
  background-color: white;
  color: var(--primary-dark-gray);
  border-radius: 30px;
  box-sizing: border-box;
  outline: none;
  border: none;
  cursor: pointer;
}

@media only screen and (min-width: 801px) {
  .map-optionsButton {
    top: calc(20px);
  }
}

/*This property makes react IGNORE onClick event on mapboxgl-marker, which is the generated div on <Marker />*/
.mapboxgl-marker {
  /*cursor: pointer;*/
  pointer-events: none;
}

.map-inMapNumber {
  font-weight: 600;
  color: white;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  pointer-events: none;
}

.map-inMapBlock {
  font-size: 22px;
  font-weight: 600;
  color: white;
  transform: translate(-50%, -50%);
}

.map-compass {
  position: absolute;
  top: 75px;
  left: 20px;
}

.map-compass .mapboxgl-ctrl-group {
  border-radius: 25px;
}

.map-compass button.mapboxgl-ctrl-compass {
  height: 45px;
  width: 45px;
}

.map-compass .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-size: contain;
}

@media only screen and (min-width: 801px) {
  .map-compass {
    top: 15px;
  }
}

.map-legendButton {
  display: inline-flex;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  color: white;
  position: absolute;
  right: 20px;
  bottom: 36px;
  padding: 13px 25px;
  border-radius: 30px;
  background-color: var(--primary-dark-gray);
  z-index: 2;
  border: none;
  outline: none;
  cursor: pointer;
}

.map-whatsappButton {
  position: absolute;
  left: 20px;
  bottom: 36px;
  text-align: center;
  height: 51px;
  width: 51px;
  border-radius: 26px;
  background-color: #25d366;
  z-index: 2;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (min-width: 801px) {
  .map-whatsappButton {
    left: 420px;
  }
}

.map-cluster {
  transform: translate(-50%, -50%);
  padding: 10px 0px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  color: black;
  background-color: white;
  border-radius: 50%;
  border: 4px solid var(--primary-red);
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
}

.map-marker {
  transform: translate(-50%, -100%);
  cursor: pointer;
  pointer-events: all !important;
}

.map-marker-body {
  padding: 14px 0px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  color: white;
  background-color: var(--primary-red);
  border-radius: 50%;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
}

.map-marker-arrow {
  margin-top: 5px;
  width: 60px;
  text-align: center;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
}

/*To make it visible*/
/*.detailHeaders {
  --detailHeaders-height: 50px;
}*/

.popUp {
  position: absolute;
  bottom: 50%;
  left: 50%;
  min-width: 300px;
  min-height: 150px;
  transform: translate(-50%, 50%);
  border-radius: 20px;
  background-color: var(--primary-light-gray);
  color: black;
  z-index: 10;
  font-size: 18px;
}

.popUp-body {
  padding: 20px 30px 35px;
}

.popUp-button {
  text-align: center;
  padding: 16px 32px;
  color: white;
  background-color: var(--primary-red);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
}

.lowProfileButton {
  background-color: white;
  color: var(--primary-dark-gray);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  outline: none;
}
