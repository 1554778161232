:root {
  --header-height: 75px;
  --filters-width: 400px;
  --close-width: 24px;
}

@media only screen and (max-width: 800px) {
  :root {
    --filters-width: 0px;
  }
  .mobile-noDisplay {
    display: none;
  }
}

.home-background-image {
  /*background-image: url("../assets/images/background.jpg");
  background-size: cover;
  background-position: center;*/
  height: 100vh;
  z-index: 1;
}

.home-background-color {
  background-color: rgba(210, 44, 63, 1);
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  z-index: 2;
}

.home-container {
  transform: translate(0px, calc(50vh - 50%));
}
@media only screen and (min-width: 801px) {
  .home-container {
    transform: translate(0px, calc(50vh - var(--header-height) / 2 - 50% - 30px));
    padding: 0px 75px;
  }
}

.home-header {
  background-color: white;
  height: var(--header-height);
  padding: 0px 70px;
  border-bottom: 1px solid var(--primary-light-gray);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 800px) {
  .home-header {
    padding: 0px 20px;
  }
}

.home-header-logo {
  height: 100%;
}

.home-profileWhite {
  position: absolute;
  width: 32px;
  top: 11px;
  right: 25px;
  padding: 8px;
  cursor: pointer;
}

.home-column {
  width: 45%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
@media only screen and (min-width: 801px) {
  .home-column-left {
    margin-right: 5%;
  }

  .home-column-right {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 800px) {
  .home-column {
    width: 90%;
    margin: 0% 5%;
  }
}

.home-inmo-logo {
  width: 70%;
  max-width: 310px;
  margin-bottom: 35px;
}

.home-input {
  font-size: 15px;
  letter-spacing: 1px;
  padding: 15px 32px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 35px;
  width: calc(95% - 64px);
  max-width: 350px;
  margin-bottom: 20px;
}

.home-search-filters {
  font-size: 15px;
  letter-spacing: 1px;
  padding: 15px 32px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 35px;
  width: 100%;
  box-sizing: border-box;
  margin: 30px 0px 15px;
}

.home-input-description {
  color: white;
  font-size: 13px;
  font-weight: 500;
  width: 85%;
  margin: 0% 7.5%;
}
@media only screen and (max-width: 800px) {
  .home-input-description {
    margin-bottom: 60px;
  }
}

.home-sugerencias {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: solid white 1px;
  width: 50%;
  cursor: pointer;
}

.home-sugerencias-selected {
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: solid white 2px;
}

.home-loteamientos-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}

.home-loteamientos-containerHeight {
  height: calc(100vh - 230px);
  overflow-y: auto;
  padding: 0px 10px;
}
@media only screen and (max-width: 800px) {
  .home-loteamientos-containerHeight {
    height: calc(100vh - 535px);
    padding: 0px;
  }
  @media only screen and (max-height: 630px) {
    .home-loteamientos-containerHeight {
      height: calc(100vh - 440px);
      padding: 0px;
    }
  }
}
@media only screen and (min-width: 801px) {
  @media only screen and (max-height: 630px) {
    .home-loteamientos-containerHeight {
      height: calc(95vh - 200px);
    }
  }
}

.home-loteamientos-emptyState {
  padding: 0px 14px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.home-loteamiento {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white;
  padding: 12px 20px;
  width: calc(48.5% - 40px);
  margin-bottom: 12px;
  border-radius: 10px;
  line-height: 1;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .home-loteamiento {
    margin-bottom: 12px;
    width: calc(50% - 50px);
  }
}

.home-loteamiento-title {
  color: var(--primary-red);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.home-loteamiento-body {
  color: var(--primary-dark-gray);
  font-size: 16px;
  font-weight: 400;
}

.home-loteamiento-left {
  margin-right: 1.5%;
}
.home-loteamiento-right {
  margin-left: 1.5%;
}
@media only screen and (max-width: 800px) {
  .home-loteamiento-left {
    margin-right: 4px;
  }
  .home-loteamiento-right {
    margin-left: 4px;
  }
}

.home-politica {
  color: white;
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  display: block;
  text-align: center;
  bottom: 40px;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .home-politica {
    bottom: 28px;
  }
}
@media only screen and (max-height: 630px) {
  .home-politica {
    display: none;
  }
}

.home-filters {
  /* Mobile */
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 3;
  background-color: white;
  height: calc(100vh - var(--header-height) - 130px);
  width: calc(100% - 34px);
  margin: 0px 17px;
  border-radius: 24px 24px 0px 0px;
  padding-top: 10px;
}
@media only screen and (min-width: 801px) {
  .home-filters {
    /*top: var(--header-height);*/
    height: calc(100vh - var(--header-height));
    width: 400px;
    margin: 0px;
    border-radius: 0px;
    padding-top: 0px;
  }
}

.home-filters-button {
  text-align: center;
  padding: 14px 20px;
  box-sizing: border-box;
  color: white;
  background-color: var(--primary-red);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  margin-top: 20px;
}

.home-filters-result-selected .home-filters-button {
  background-color: white !important;
  color: var(--primary-dark-gray) !important;
  cursor: default !important;
}

.home-header-profile {
  display: flex;
  width: 63px;
  justify-content: flex-end;
  cursor: pointer;
}

.home-back {
  box-sizing: border-box;
  height: var(--header-height);
  padding-top: calc(var(--header-height) / 2 - 10px);
  cursor: pointer;
  width: 63px; /*for symmetry with profile*/
}

.home-back-arrow {
  height: 16px;
  margin-right: 20px;
}

.home-back-text {
  line-height: 1.1;
  vertical-align: top;
}

.home-filters-header {
  width: calc(100% - 20px);
  margin: 0px 10px 15px;
  padding: 15px 30px 12px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--primary-light-gray);
  font-size: 18px;
  font-weight: bold;
}

.home-filters-header-title {
  display: inline-block;
  width: calc(100% - var(--close-width));
  vertical-align: top;
}

.home-filters-header-close {
  width: var(--close-width);
  display: inline-block;
  vertical-align: middle;
}

.home-filters-dropdown {
  appearance: none;
  padding: 18px 22px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border: none;
  width: 100%;
  box-sizing: border-box;
  background: url("../assets/images/interface/dropdown.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: top 26px right 22px;
  outline: none;
}

.extraLength {
  width: calc(100% - 188px) !important;
}

.home-mapInput {
  margin: 0px;
  position: absolute;
  top: calc(var(--header-height) + 10px + var(--detailHeaders-height));
  left: 20px;
  width: calc(100% - 214px);
  z-index: 2;
  border-radius: 30px 5px 5px 30px;
  max-width: 750px !important;
}
@media only screen and (min-width: 801px) {
  .home-mapInput {
    border-radius: 30px;
    width: calc(var(--filters-width) - 104px);
  }
}

.home-filtersButton {
  min-width: 73px;
  height: 49px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
  padding: 15px 26px;
  position: absolute;
  top: calc(var(--header-height) + 10px + var(--detailHeaders-height));
  right: 20px;
  color: white;
  background-color: var(--primary-red);
  z-index: 2;
  border: none;
  outline: none;
  border-radius: 5px 30px 30px 5px;
  cursor: pointer;
}

.home-filters-body {
  padding: 0px 25px;
  box-sizing: border-box;
  height: calc(100% - 100px);
  overflow-y: auto;
}
@media only screen and (max-width: 800px) {
  .home-filters-body {
    margin-top: 15px;
    height: calc(100% - 153px);
  }
}

.home-filters-tags {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 35px;
}
/*.home-filters-tags::-webkit-scrollbar {
  display: none;
}*/

.home-filters-tag {
  display: inline-block;
  padding: 8px 20px;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: var(--primary-dark-gray);
  letter-spacing: 0.5px;
  margin-right: 10px;
}

.home-filters-closeTag {
  height: 20px;
  margin-left: 10px;
  vertical-align: bottom;
}

.home-filters-title {
  padding: 0px 22px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

/*React input range component*/
.input-range {
  width: calc(100% - 20px) !important;
  margin: 5px 10px 5px;
}
.input-range__label {
  display: none;
}
.input-range__track--background {
  background-color: var(--primary-light-gray) !important;
}
.input-range__track--active,
.input-range__slider {
  border: none !important;
  background-color: var(--primary-red) !important;
}

.home-filters-checkContainer {
  display: inline-block;
  white-space: nowrap;
}

.home-filters-checkLabel {
  margin-left: 10px;
  margin-right: 25px;
  vertical-align: top;
  line-height: 1.7;
  white-space: nowrap;
}

.home-filters-redButton {
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 25px;
  margin-top: 20px;
  text-align: center;
  width: calc(50% - 30px);
  padding: 14px 23px;
  color: white;
  background-color: var(--primary-red);
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}

.home-filters-whiteButton {
  box-sizing: border-box;
  margin-left: 25px;
  margin-right: 5px;
  margin-top: 20px;
  text-align: center;
  width: calc(50% - 30px);
  padding: 14px 23px;
  color: "#555555";
  background-color: var(--primary-light-gray);
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}

.home-filters-whiteButton-fullwidth {
  box-sizing: border-box;
  margin: 20px 25px 0px;
  text-align: center;
  width: calc(100% - 50px);
  padding: 14px 23px;
  color: "#555555";
  background-color: var(--primary-light-gray);
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 30px;
}

.home-filters-result {
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  width: calc(100% - 15px);
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 18px;
  cursor: pointer;
}

.home-filters-result-selected {
  color: white !important;
  background-color: var(--primary-dark-gray);
  cursor: inherit;
}

.home-filters-resultTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}

.home-filters-resultSubtitle {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.4;
}

.home-filters-resultBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 375px) {
  .home-filters-whiteButton,
  .home-filters-redButton {
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 50px);
  }
  .home-filters-redButton {
    margin-top: 12px;
  }
  .home-filters-body {
    height: calc(100% - 215px);
  }
}

.home-detailHeader {
  position: absolute;
  top: var(--header-height);
  left: var(--filters-width);
  height: var(--detailHeaders-height);
  width: calc(100% - var(--filters-width));
  background-color: white;
  color: var(--primary-dark-gray);
  text-align: center;
  font-size: 22px;
}

.home-detailHeader-title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  height: calc(var(--detailHeaders-height) / 3);
  background-color: var(--primary-dark-gray);
  color: white;
}

.home-detailHeader-body {
  font-size: 16px;
  width: 100%;
  height: calc(var(--detailHeaders-height) / 3);
  display: flex;
  justify-content: space-between;
  color: var(--primary-dark-gray);
  background-color: white;
  box-sizing: border-box;
  padding: 0px 10px;
}

/*.home-goBackToList {
  letter-spacing: 0.4px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 1.1;
  padding: 13px 25px;
  position: absolute;
  top: calc(150px + var(--detailHeaders-height));
  left: 20px;
  z-index: 2;
  background-color: var(--primary-red);
  color: white;
  border-radius: 30px;
  box-sizing: border-box;
  outline: none;
  border: none;
}
@media only screen and (min-width: 801px) {
  .home-goBackToList {
    top: calc(90px + var(--detailHeaders-height));
    left: 420px;
  }
}*/
