.white-body {
  padding: 16px 16px 22px;
  background-color: white;
  border-radius: 0px 0px 24px 24px;
  text-align: center;
  max-width: 600px;
  box-sizing: border-box;
}
@media only screen and (min-width: 601px) {
  .white-body {
    padding: 18px 18px 25px;
    margin-left: calc(50% - 300px);
  }
}

.white-body-fixed {
  min-height: calc(100vh - 200px);
}

.login-title {
  color: var(--primary-dark-gray);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}

.login-description {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-dark-gray);
}

.login-social-button {
  color: var(--primary-dark-gray);
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  width: calc(50% - 10px);
  max-width: 250px;
  border-radius: 10px;
  margin: 0px 5px;
  border: none;
  outline: none;
}

.login-label {
  font-size: 18px;
  color: var(--primary-dark-gray);
  margin: 0px 10px 14px;
  text-align: start;
}

.login-input {
  border: none;
  outline: none;
  padding: 7px 0px 11px;
  font-size: 16px;
  border-bottom: 2px solid var(--primary-light-gray);
  width: 100%;
}

.login-input-halfWidth {
  border: none;
  outline: none;
  padding: 7px 0px 11px;
  font-size: 16px;
  border-bottom: 2px solid var(--primary-light-gray);
  width: calc(50% - 12px);
}

.login-input-resetPassword {
  width: calc(100% - 50px) !important;
}

.login-select {
  padding: 16px 24px;
  width: calc(100% - 20px);
  background-color: white;
  border-radius: 10px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  border: none;
  outline: none;
  margin: 0px 10px 33px;
}

.passwordShowerTrigger {
  height: 48px;
  width: 50px;
  background-color: white;
  border: none !important;
  outline: none !important;
  font-size: 22px;
  color: var(--primary-dark-gray);
}

.login-getPassword-link {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-red);
  text-align: end;
  cursor: pointer;
}

.login-link {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-dark-gray);
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.login-button-red {
  text-align: center;
  padding: 16px 50px;
  color: white;
  background-color: var(--primary-red);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
}
.login-button-red:disabled,
.login-button-red[disabled] {
  opacity: 0.5;
}

.button-smallPadding {
  padding: 16px 30px !important;
}

.login-checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login-checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  width: 19px;
  border-radius: 5px;
  border: 2px solid var(--primary-red);
  background-color: white;
  margin-right: 15px;
  cursor: pointer;
}

.checkbox-marked {
  background-color: var(--primary-red) !important;
}

.login-back {
  text-align: start;
  cursor: pointer;
  height: 32px;
}
