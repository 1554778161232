.confirmAddCard {
  height: 100vh;
}

.confirmAddCard-container {
  text-align: center;
  padding: 50px 40px 70px;
  font-size: 14px;
}

.confirmAddCard-status {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}

.confirmAddCard-button {
  text-align: center;
  padding: 16px 50px;
  color: white;
  background-color: var(--primary-red);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  border: none;
  outline: none;
  border-radius: 30px;
}
